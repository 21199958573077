import React, { useCallback, useEffect, useState } from 'react';
import styles from './AutoplusAnalyticsPage.module.scss';
import { RouteComponentProps } from 'react-router-dom';
import { chain, cloneDeep, get, map, omit, reverse } from 'lodash';
import base64url from 'base64-url';
import sideBars, { MenuItem } from '../../cores/sideBars';
import { useDispatch } from 'react-redux';
import { AnalyticsActions, AnalyticsTypes } from '../../store/analytics';
import axios, { CancelTokenSource } from 'axios';
import { useTypedSelector } from '../../cores/useTypedSelector';
import Chart from '../../components/Chart/Chart';
import getQuery from '../../cores/getQuery';
import Table from '../../components/Table/Table';
import Loading from '../../components/Loading/Loading';
import { ResponsiveActions } from '../../store/responsive';
import { MdFileDownload } from 'react-icons/md';
import Ink from 'react-ink';
import { toTableData } from '../../cores/toTableData';
import XLSX from 'xlsx';
import { DateTime } from 'luxon';
import classNames from 'classnames';
import Layout from '../../components/Layout';
import DatePicker from '../../components/DatePicker/DatePicker';
import DateFilter from '../../components/DateFilter/DateFilter';
import XlsxDownLoadButton from '../../components/XlsxDownLoadButton/XlsxDownLoadButton';

interface Props {}

let source: CancelTokenSource | null = null;

const AutoplusAnalyticsPage: React.FC<Props & RouteComponentProps> = ({ location: { search, key: locationKey }, history }) => {
  const query = getQuery(search) as any;
  const key = get(query, 'key');
  const dispatch = useDispatch();
  useEffect(() => {
    if (!menuItem) {
      return;
    }

    if (source) {
      source.cancel();
    }
    source = axios.CancelToken.source();
    dispatch(AnalyticsActions.getAnalytics(menuItem.endPoint, omit(query, ['key']), source.token));
    return () => {
      dispatch(AnalyticsActions.truncateAnalytics());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationKey]);

  let { data, isLoading } = useTypedSelector(({ analytics: { data }, loading: { asyncMap } }) => ({
    data,
    isLoading: get(asyncMap, AnalyticsTypes.getAnalytics) > 0
  }));

  const onInvisibleSidebar = useCallback(() => {
    dispatch(ResponsiveActions.setVisibleSidebar(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    onInvisibleSidebar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationKey]);

  if (!key) {
    history.push({ pathname: '/' });
    return null;
  }

  const decodedKey = base64url.decode(key);

  const menuItem: MenuItem | null = chain(sideBars)
    .flatMap('menuItemList')
    .find(({ label }) => label === decodedKey)
    .value();

  if (menuItem === null) {
    history.push({ pathname: '/' });
    return null;
  }

  const tableData = data && toTableData(data, menuItem.tableDataOptions!);

  const onClickXlsxDownLoad = () => {
    const header = map(menuItem.tableDataOptions!.fields, 'name');
    const xlsxData = map(tableData, data =>
      chain(data)
        .map(({ value }, key) => ({ [header[key]]: value }))
        .reduce((a, b) => ({
          ...a,
          ...b
        }))
        .value()
    );
    const workSheet = XLSX.utils.json_to_sheet(xlsxData, {
      header
    });
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet);
    XLSX.writeFile(workBook, `${menuItem.label}-${DateTime.local().toFormat('yyyyMMddHHmmss')}.xlsx`);
  };

  return (
    <Layout>
      <div className={styles.content} id="content">
        <div className={styles.header}>
          <div className={styles.title}>Autoplus 활동현황</div>
          <div className={styles.datePickerWrapper}>
            <DateFilter endPoint={menuItem.endPoint} />
          </div>
        </div>
        {isLoading ? (
          <Loading />
        ) : (
          <div className={styles.body}>
            <>
              {menuItem.chartDataOptions && (
                <div className={styles.chartWrapper}>
                  {!isLoading && data && <Chart data={reverse(cloneDeep(data))} defaultOptions={menuItem.chartDataOptions} />}
                </div>
              )}
              {menuItem.tableDataOptions && (
                <div
                  className={classNames(styles.tableWrapper, menuItem.endPoint === 'analytics/user_status' && styles.isUserState)}
                >
                  <div className={styles.tableHeader}>
                    <h1 className={styles.title}>{menuItem.label}</h1>
                    <XlsxDownLoadButton onClick={onClickXlsxDownLoad} />
                  </div>
                  {data && <Table tableData={tableData} defaultOptions={menuItem.tableDataOptions!} />}
                </div>
              )}
            </>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default AutoplusAnalyticsPage;
