import axios, { AxiosInstance, AxiosInterceptorManager, AxiosRequestConfig, AxiosResponse } from 'axios';

import { store } from '../index';
import { ToastActions, ToastMessageTypes } from '../store/toast';
import { getAuthorizationHeader } from '../cores/getAuthorizationHeader';

interface PxiosResponseData<T = any> extends AxiosResponse<T> {
  toast?: { message: string; type: ToastMessageTypes };
}

interface PxiosInstance extends AxiosInstance {
  interceptors: {
    request: AxiosInterceptorManager<AxiosRequestConfig>;
    response: AxiosInterceptorManager<AxiosResponse<PxiosResponseData>>;
  };
}

const pxios: PxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_HOST,
  headers: getAuthorizationHeader()
});
pxios.interceptors.response.use(
  response => {
    if (response.data.toast) {
      const { message, type } = response.data.toast;
      store.dispatch(ToastActions.createToast({ type, message }));
    }

    return response;
  },
  err => {
    if (err.response?.data?.toast) {
      const { message, type } = err.response.data.toast;
      store.dispatch(ToastActions.createToast({ type, message }));
    }
    throw new Error(err.response.data.toast.message);
  }
);

export const setAuthorizedRequesterToken = (token: string) => {
  pxios.defaults.headers['Authorization'] = `JWT ${token}`;
};

export default pxios;
