import { Images } from '../images';
import { ChartDataOptions, Color } from './toChartData';
import { TableDataOptions } from './toTableData';
import { DateTime } from 'luxon';

export type DeviceType = 'android' | 'ios' | 'pc';
export type Frequency = 'daily' | 'weekly' | 'monthly';
export type DealerGroup = 'autoplus' | 'group1' | 'group2' | 'group3';
export type CompetitorType = 'encarauction' | 'b2b' | 'offline' | 'c2b_trade';

export type Order = 'recent' | 'old';
export type Type = 'weekly' | 'monthly';
export type Status = 'pending' | 'postpone' | 'completed';
export type CarRegistrationStatusType = 'cars_count' | 'sold_cars_count' | 'purchased_cars_count';

export interface Params {
  is_competing?: boolean;
  status?: Status;
  type?: Type;
  order?: Order;
}

export interface MenuItem {
  label: string;
  endPoint: string;
  params?: Params;
}

interface SideBar {
  category: string;
  icon: keyof Images;
  alt: string;
  menuItemList: Array<MenuItem>;
}

export interface Params {
  deviceTypes?: Array<DeviceType>;
  frequency?: Frequency;
  start_date?: string;
  end_date?: string;
  dealerGroup?: DealerGroup;
}

export type TrackingTableDataOptions = Array<{
  name?: string;
  value: string;
  fields?: Array<{ name: string; value: string; numeric?: boolean }>;
}>;

export interface MenuItem {
  label: string;
  endPoint: string;
  dealerGroup?: DealerGroup;
  chartDataOptions?: ChartDataOptions;
  tableDataOptions?: TableDataOptions;
  trackingDataOptions?: TrackingTableDataOptions;
  params?: Params;
}

const sideBars: Array<SideBar> = [
  {
    category: '현황',
    icon: 'gnbChart',
    alt: '현황',
    menuItemList: [
      {
        label: '담당자 리스트',
        endPoint: '/',
        params: { order: 'recent' }
      }
    ]
  },
  {
    category: '통계',
    icon: 'gnbDealer',
    alt: '통계',
    menuItemList: [
      {
        label: '담당자 별 데이터',
        endPoint: '/analytics/users',
        params: { start_date: '2019-01-01' }
      },
      {
        label: 'Autoplus 활동현황',
        chartDataOptions: {
          fields: [
            { name: '견적 수', value: 'bids_count', color: Color.gray, disabled: true },
            { name: '선택차량 수', value: 'selected_cars_count', color: Color.blue },
            { name: '거래차량 수', value: 'traded_cars_count', color: Color.red }
          ]
        },
        tableDataOptions: {
          fields: [
            { name: '날짜', value: 'date' },
            { name: '견적 수', value: 'bids_count', color: Color.gray },
            { name: '선택차량 수', value: 'selected_cars_count', color: Color.blue },
            { name: '거래차량 수', value: 'traded_cars_count', color: Color.red }
          ]
        },
        endPoint: '/group_activity',
        params: { start_date: '2019-01-01' }
      },
      {
        label: '입찰 상세데이터',
        endPoint: '/bid_data',
        params: { start_date: '2021-01-01', end_date: DateTime.local().toFormat('yyyy-MM-dd') }
      }
    ]
  }
];

export default sideBars;
