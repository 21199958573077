import React, { useState } from 'react';
import styles from './BidDetailData.module.scss';
import Layout from '../../components/Layout';
import Content from '../../components/Content/Content';
import DatePicker from '../../component-system/DatePicker/DatePicker';
import { useRouter } from '../../cores/useRouter';
import { DateTime } from 'luxon';
import FieldSet from './FieldSet/FieldSet';
import { useQuery } from 'react-query';
import axios from 'axios';
import { getAuthorizationHeader } from '../../cores/getAuthorizationHeader';
import { map } from 'lodash';
import DropdownField from '../../component-system/DropdownField/DropdownField';
import XlsxDownLoadButton from '../../components/XlsxDownLoadButton/XlsxDownLoadButton';
import { useToast } from '../../cores/useToast';

const BidDetailData: React.FC<{}> = () => {
  const { query, history, location } = useRouter();
  const startDate = query.get('start_date');
  const endDate = query.get('end_date');
  const toast = useToast();
  // const officeData = useQuery('officesData', () => axios.get<string[]>(`/offices/`, { headers: getAuthorizationHeader() }));
  const [isLoading, setLoading] = useState(false);

  // const offices = officeData?.data?.data;
  // const officeOptions = map(offices, office => ({ name: office, value: office }));

  const onClickDatePicker = (startDate: DateTime | null, endDate: DateTime | null) => {
    if (startDate && endDate) {
      query.set('start_date', startDate.toFormat('yyyy-MM-dd'));
      query.set('end_date', endDate.toFormat('yyyy-MM-dd'));
    } else if (startDate && !endDate) {
      query.set('start_date', startDate.toFormat('yyyy-MM-dd'));
      query.delete('end_date');
    } else {
      query.delete('start_date');
      query.delete('end_date');
    }

    history.push(`${location.pathname}?${query.toString()}`);
  };

  const onClickDownLoadXlsx = () => {
    if (!startDate || !endDate) {
      toast.error('데이터 기간을 선택해 주세요.');
      return;
    }

    query.delete('key');
    // if (office) {
    //   query.append('office', office);
    // }

    setLoading(true);
    axios
      .get<string[]>(`bid/download/`, {
        headers: getAuthorizationHeader(),
        params: { check_only: true, start_date: startDate, end_date: endDate /* , office */ }
      })
      .then(res => {
        axios({
          url: `/bid/download/?${query.toString()}`,
          method: 'GET',
          responseType: 'blob',
          headers: getAuthorizationHeader()
        }).then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `입찰데이터(${startDate} ~ ${endDate}).xlsx`);
          document.body.appendChild(link);
          link.click();
          setLoading(false);
        });
      })
      .catch(() => {
        toast.error('요청하신 날짜의 데이터가 없습니다. 날짜 혹은 지점을 수정해 주세요.');
        setLoading(false);
      });
  };

  return (
    <Layout>
      <Content>
        <div className={styles.header}>
          <div className={styles.title}>입찰 상세데이터</div>
          <FieldSet label="데이터 기간">
            <div className={styles.datePickerWrapper}>
              <DatePicker
                initialDate={new Date('2020/01/01')}
                onClick={onClickDatePicker}
                initialStartDate={query.get('start_date')}
                initialEndDate={query.get('end_date')}
              />
            </div>
          </FieldSet>
          {/* <FieldSet label="소속 지점">
            <DropdownField
              options={[{ name: '전체 autoplus 지점', value: null }, ...officeOptions]}
              value={office}
              onChange={value => {
                setOffice(value as string);
              }}
            />
          </FieldSet> */}
        </div>
        <div className={styles.content}>
          <XlsxDownLoadButton onClick={onClickDownLoadXlsx} isLoading={isLoading} />
        </div>
      </Content>
    </Layout>
  );
};

export default BidDetailData;
