import React, { ReactNode } from 'react';
import Content from './Content/Content';
import Body from './Body/Body';
import Header from './Header/Header';
import PageWrapper from './PageWrapper/PageWrapper';
import SideBar from './SideBar/SideBar';

interface Props {
  children: ReactNode;
  title?: string;
  isLogin?: boolean;
}

const Layout: React.FC<Props> = ({ children, title = 'autoplus', isLogin = false }) => {
  return (
    <PageWrapper title={title}>
      <Header isLogin={isLogin} />
      <Body>
        {!isLogin && <SideBar />}
        {children}
      </Body>
    </PageWrapper>
  );
};

export default Layout;
