import React, { FC, useCallback, useRef } from 'react';
import styles from './Login.module.scss';
import { Formik } from 'formik';
import InputField from '../InputField/InputField';
import Ink from 'react-ink';
import { useToast } from '../../cores/useToast';
import { useDispatch } from 'react-redux';
import { AuthenticationActions, AuthenticationTypes } from '../../store/authentication';
import { get } from 'lodash';
import classNames from 'classnames';
import { useTypedSelector } from '../../cores/useTypedSelector';
import logoCenter from '../../images/logoCenter.png';

interface Props {}

const Login: FC<Props> = () => {
  const usernameRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const isLoading = useTypedSelector(({ loading: { asyncMap } }) => get(asyncMap, AuthenticationTypes.setLogin) > 0);

  const toast = useToast();
  const dispatch = useDispatch();
  const onSubmit = useCallback(
    (values: { username: string; password: string }) => {
      if (isLoading) {
        toast.warning('이미 로그인 중입니다.');
        return;
      }

      if (!values.username && usernameRef.current) {
        usernameRef.current.focus();
        toast.warning('아이디를 입력해주세요.');
        return;
      }

      if (!values.password && passwordRef.current) {
        passwordRef.current.focus();
        toast.warning('비밀번호를 입력해주세요.');
        return;
      }

      dispatch(AuthenticationActions.setLogin(values));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoading]
  );

  return (
    <div className={styles.login}>
      <Formik onSubmit={onSubmit} initialValues={{ username: '', password: '' }}>
        {({ values, handleSubmit, handleChange }) => {
          return (
            <form className={styles.form} onSubmit={handleSubmit}>
              <div className={styles.logoWrapper}>
                <img src={logoCenter} alt="로고" className={styles.logo} />
              </div>
              <InputField
                ref={usernameRef}
                placeholder="아이디"
                name="username"
                value={values.username}
                onChange={handleChange}
                inputClassName={styles.idInput}
              />
              <InputField
                ref={passwordRef}
                placeholder="비밀번호"
                type="password"
                name="password"
                value={values.password}
                onChange={handleChange}
                inputClassName={styles.passwordInput}
              />
              <div className={styles.actions}>
                <button className={classNames(styles.submit, isLoading && styles.isLoading)} type="submit">
                  로그인
                  <Ink />
                </button>
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default Login;
